import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  flexShrink: 0,
  borderRadius: '20px',
  background: theme.palette.background.main,
  boxShadow: theme.palette.textFieldBackground.boxShadow,
}));

const FlexContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '20px',
}));

const Secondary = styled(Typography)(({ theme }) => ({
  color: theme.palette.earnedPointsSeconday.color,
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  textTransform: 'capitalize',
}));
const SecondaryBold = styled(Typography)(({ theme }) => ({
  color: theme.palette.earnedPointsSeconday.color,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  textTransform: 'capitalize',
}));

const EarnedPointsCards = ({
  title,
  total_redeem_points,
  total_scanned_points,
  totalValue,
  contact_number
}) => {
  const theme = useTheme();
  return (
    <StyledBox>
      <Box sx={{ paddingTop: '20px' }}>
        <Typography fontWeight="bold" paddingLeft="20px" fontSize="16px">
          {title}
          <br/>
        <Typography variant='caption' fontSize="16px">
         ({contact_number})
        </Typography>
        </Typography>
        <FlexContainer>
          <SecondaryBold>Total Scanned Points</SecondaryBold>
          <Box
            sx={{
              padding: '2px 7px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              background: theme.palette.earnedPointsPendingValue.background,
            }}
          >
            <Typography color="primary" fontWeight="bold">
              {total_scanned_points}
            </Typography>
          </Box>
        </FlexContainer>
        <FlexContainer>
          <SecondaryBold>Total Redeeem Points</SecondaryBold>
          <Typography sx={{ fontWeight: 'bold' }}>
            {total_redeem_points}
          </Typography>
        </FlexContainer>
      </Box>
      <Box paddingTop="7px">
        <FlexContainer
          sx={{
            background: theme.palette.primary.color,
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '20px',
            height: '40px',
            paddingBottom: '17px',
          }}
        >
          <Typography color={'secondary'} sx={{ fontWeight: 'bold' }}>
            Total
          </Typography>
          <Typography color={'secondary'} sx={{ fontWeight: 'bold' }}>
            {totalValue}
          </Typography>
        </FlexContainer>
      </Box>
    </StyledBox>
  );
};

export default EarnedPointsCards;
