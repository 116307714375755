import React from 'react';
import { IconButton } from '@mui/material';
import * as XLSX from 'xlsx';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';

const XLSXDownload = ({ rows }) => {
  const filteredRows = rows?.filter(
    (rows) => rows.id !== 'is_active' && rows.id !== 'actions'
  );

  const handleDownloadXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  return (
    <IconButton
      onClick={handleDownloadXLSX}
      aria-label="Download XLSX"
      style={{ color: 'green' }}
    >
      <ArticleRoundedIcon />
    </IconButton>
  );
};

export default XLSXDownload;
