import React, { useState } from 'react';
import TableData from '../../../commons/table/table';
import { Box, Grid, TextField, Typography } from '@mui/material';
import GradientPageButton from '../../../commons/gradiantComponents/gradientButtonPage';
import { useTheme } from '@emotion/react';

const PointsPage = ({
  columns,
  rows: rowData,
  data,
  getPoints,
  rowsPerPage,
  page,
  total,
  getPage,
  start_date,
  end_date,
  totalRecords,
  searchFields,
  search,
}) => {
  const pageName = 'Reports';
  const userData = localStorage.getItem('userdata');
  const user = JSON.parse(userData);
  const name = useState(user.name);
  const theme = useTheme();
  const [selectedValues, setSelectedValues] = useState([]);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSearchTermChange = (event, key) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [key]: event.target.value,
    }));
  };

  const minDate = new Date(2001, 0, 1);
  const currentDate = new Date();
  const formattedMinDate = getFormattedDate(minDate);
  const formattedCurrentDate = getFormattedDate(currentDate);

  const [dateFrom, setDateFrom] = useState(start_date);
  const [dateTo, setDateTo] = useState(end_date);

  const handleSearch = () => {
    const searchData = { start_date: dateFrom, end_date: dateTo };
    if (selectedValues.contact_number) {
      searchData.contact_number = selectedValues.contact_number;
    }
    if (!searchData.contact_number) getPoints(searchData);
    else search(searchData);
  };

  const filteredColumns = columns.filter((col) => data.includes(col.id));

  return (
    <Box>
      <Box sx={{ mx: '40px', my: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'space-between',
            marginBottom: '24px',
            alignItems: { md: 'auto', xs: 'center' },
          }}
        >
          <Box sx={{ marginBottom: { md: 'auto', xs: '24px' } }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: { md: 'row', xs: 'column' },
              }}
            >
              <Typography
                sx={{
                  color: 'text.headings',
                  fontFamily: 'Poppins',
                  fontSize: 28,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '36px',
                  textTransform: 'capitalize',
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  color: 'text.headings',
                  fontFamily: 'Poppins',
                  fontSize: 28,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '36px',
                  textTransform: 'capitalize',
                }}
              >
                ,&nbsp;{pageName}&nbsp;
                {/* (<span style={{ fontSize: '80%' }}>s</span>) */}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: 'text.headings',
                fontFamily: 'Poppins',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
                textTransform: 'uppercase',
              }}
            >
              List of all the {name} &nbsp;{pageName}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: { md: 'flex', xs: 'auto' } }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { md: 'row', xs: 'column' },
                  gap: { md: '16px', xs: '0' },
                }}
              >
                <TextField
                  label="Date From"
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { md: 'calc(50% - 8px)', xs: '100%' },
                    marginBottom: { xs: '16px', md: '0' },
                  }}
                  inputProps={{
                    min: formattedMinDate,
                    max: formattedCurrentDate,
                  }}
                />
                <TextField
                  label="Date To"
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { md: 'calc(50% - 8px)', xs: '100%' },
                  }}
                  inputProps={{
                    min: formattedMinDate,
                    max: formattedCurrentDate,
                  }}
                />
              </Box>
            </Grid>
            {searchFields?.map((col) => (
              <Grid item xs={12} md={2} key={col.id}>
                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    padding: '12px 20px 12px 20px',
                    borderRadius: 4,
                    border: '2px solid #DEDEDE',
                    background: '#FFF',
                    boxShadow: `0px 4px 12px ${theme.palette.shadow.boxShadowColor}`,
                    '&:hover': {
                      boxShadow: `0px 6px 16px ${theme.palette.shadow.boxShadowColorHover}`,
                    },
                    '&:focus': {
                      boxShadow: `0px 8px 20px ${theme.palette.shadow.boxShadowColorFocus}`,
                    },
                  }}
                  placeholder={`Search ${col.label}`}
                  name={col.id}
                  value={selectedValues[col.id]}
                  onChange={(e) => handleSearchTermChange(e, 'contact_number')}
                />
              </Grid>
            ))}
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  height: '100%',
                  width: { md: 'calc(50% - 8px)', xs: '100%' },
                }}
              >
                <GradientPageButton name="Search" onClick={handleSearch} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <TableData
          columns={filteredColumns}
          rows={rowData}
          func={null}
          temp={null}
          toggle={null}
          rowsPPage={rowsPerPage}
          pageNo={page}
          total={total}
          getPage={getPage}
          totalRecords={totalRecords}
        />
      </Box>
    </Box>
  );
};

export default PointsPage;
