import React from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

const PDFDocument = ({ columns, rows }) => {
  const filteredColumns = columns?.filter(
    (column) => column.id !== 'is_active' && column.id !== 'actions'
  );

  const columnWidth = 100 / filteredColumns.length;

  const styles = StyleSheet.create({
    page: { flexDirection: 'row', backgroundColor: '#ffffff' },
    table: { display: 'table', width: '100%' },
    tableRow: { flexDirection: 'row' },
    tableCell: {
      borderWidth: 1,
      borderColor: '#000',
      padding: 5,
      minWidth: columnWidth + '%',
      maxWidth: columnWidth + '%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    text: {},
  });

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {filteredColumns?.map((column) => (
                <View style={styles.tableCell} key={column.id}>
                  <Text noWrap>{column.label}</Text>
                </View>
              ))}
            </View>
            {rows?.map((row, index) => (
              <View style={styles.tableRow} key={index}>
                {filteredColumns?.map((column) => (
                  <View style={styles.tableCell} key={column.id}>
                    <Text noWrap>{row[column.id]}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
